<template>
  <div class="profile-dropdown-wrapper">
    <VaDropdown v-model="isShown" :offset="[9, 0]" class="profile-dropdown" stick-to-edges>
      <template #anchor>
        <VaButton preset="secondary" color="textPrimary">
          <span class="profile-dropdown__anchor min-w-max">
            <slot />
            <VaAvatar :size="32" color="warning">
              <svg xmlns="http://www.w3.org/2000/svg" width="4rem" height="4rem" viewBox="0 0 24 24">
                <rect width="24" height="24" fill="none" />
                <path
                  fill="#ff3842"
                  d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12S17.937 22.75 12 22.75S1.25 17.937 1.25 12M12 2.75a9.25 9.25 0 1 0 0 18.5a9.25 9.25 0 0 0 0-18.5"
                />
                <path
                  fill="#ff3842"
                  d="M12 7.75c-1.28 0-2.25 1-2.25 2.25s.97 2.25 2.25 2.25s2.25-1 2.25-2.25s-.97-2.25-2.25-2.25M8.25 10A3.72 3.72 0 0 1 12 6.25A3.72 3.72 0 0 1 15.75 10A3.72 3.72 0 0 1 12 13.75A3.72 3.72 0 0 1 8.25 10m6.455 5.861a.7.7 0 0 0-.216.101c-.501.338-1.374.788-2.489.788s-1.988-.45-2.489-.788a.7.7 0 0 0-.216-.1a.2.2 0 0 0-.103-.004c-.692.216-1.419.581-1.972 1.157c-.538.56-.944 1.35-.97 2.502a.75.75 0 1 1-1.5-.034c.035-1.53.593-2.68 1.388-3.507c.782-.813 1.76-1.286 2.608-1.55c.626-.195 1.216.03 1.604.293c.36.242.94.531 1.65.531s1.29-.29 1.65-.531c.388-.262.978-.488 1.604-.293c.849.264 1.826.737 2.608 1.55c.795.828 1.353 1.976 1.388 3.507a.75.75 0 1 1-1.5.034c-.026-1.151-.432-1.942-.97-2.502c-.553-.576-1.28-.941-1.972-1.157a.2.2 0 0 0-.103.003"
                  opacity="0.5"
                />
              </svg>
            </VaAvatar>
          </span>
        </VaButton>
      </template>
      <VaDropdownContent
        class="profile-dropdown__content md:w-60 px-0 py-4 w-full"
        :style="{ '--hover-color': hoverColor }"
      >
        <VaList v-for="group in options" :key="group.name">
          <header v-if="group.name" class="uppercase text-[var(--va-secondary)] opacity-80 font-bold text-xs px-4">
            {{ t(`user.${group.name}`) }}
          </header>
          <VaListItem
            v-for="item in group.list"
            :key="item.name"
            class="menu-item px-4 text-base cursor-pointer h-8"
            v-bind="resolveLinkAttribute(item)"
          >
            <VaIcon :name="item.icon" class="pr-1" color="secondary" />
            {{ t(`user.${item.name}`) }}
          </VaListItem>
          <VaListSeparator v-if="group.separator" class="mx-3 my-2" />
        </VaList>
      </VaDropdownContent>
    </VaDropdown>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'

const { colors, setHSLAColor } = useColors()
const hoverColor = computed(() => setHSLAColor(colors.focus, { a: 0.1 }))

const { t } = useI18n()

type ProfileListItem = {
  name: string
  to?: string
  href?: string
  icon: string
}

type ProfileOptions = {
  name: string
  separator: boolean
  list: ProfileListItem[]
}

withDefaults(
  defineProps<{
    options?: ProfileOptions[]
  }>(),
  {
    options: () => [
      {
        name: 'account',
        separator: true,
        list: [
          {
            name: 'profile',
            to: 'preferences',
            icon: 'mso-account_circle',
          },
          // {
          //   name: 'settings',
          //   to: 'settings',
          //   icon: 'mso-settings',
          // },
          // {
          //   name: 'billing',
          //   to: 'billing',
          //   icon: 'mso-receipt_long',
          // },
          // {
          //   name: 'projects',
          //   to: 'projects',
          //   icon: 'mso-favorite',
          // },
        ],
      },
      // {
      //   name: 'explore',
      //   separator: true,
      //   list: [
      //     {
      //       name: 'faq',
      //       to: 'faq',
      //       icon: 'mso-quiz',
      //     },
      //     {
      //       name: 'helpAndSupport',
      //       href: 'https://discord.gg/u7fQdqQt8c',
      //       icon: 'mso-error',
      //     },
      //   ],
      // },
      {
        name: '',
        separator: false,
        list: [
          {
            name: 'logout',
            to: 'login',
            icon: 'mso-logout',
          },
        ],
      },
    ],
  },
)

const isShown = ref(false)

const resolveLinkAttribute = (item: ProfileListItem) => {
  return item.to ? { to: { name: item.to } } : item.href ? { href: item.href, target: '_blank' } : {}
}
</script>

<style lang="scss">
.profile-dropdown {
  cursor: pointer;

  &__content {
    .menu-item:hover {
      background: var(--hover-color);
    }
  }

  &__anchor {
    display: inline-block;
  }
}
</style>
