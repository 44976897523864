export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'Dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'master',
      displayName: 'Master Data',
      meta: {
        icon: 'credit_card',
      },
      children: [
        {
          name: 'users',
          displayName: 'Admin',
        },
        {
          name: 'employee',
          displayName: 'Karyawan',
        },
        {
          name: 'business_unit',
          displayName: 'Bisnis Unit',
        },
        {
          name: 'meeting_room',
          displayName: 'Ruang Meeting',
        },
        {
          name: 'vehicle',
          displayName: 'Kendaraan',
        },
      ],
    },
    {
      name: 'dinas_keluar',
      displayName: 'Dinas Keluar',
      meta: {
        icon: 'rv_hookup',
      },
    },
    {
      name: 'booking_meeting',
      displayName: 'Booking Meeting',
      meta: {
        icon: 'meeting_room',
      },
    },
    {
      name: 'appr_book_meeting',
      displayName: 'Appr Booking Meeting',
      meta: {
        icon: 'recommend',
      },
    },
    {
      name: 'booking_kendaraan',
      displayName: 'Booking Kendaraan',
      meta: {
        icon: 'airport_shuttle',
      },
    },
    {
      name: 'appr_mgr_kendaraan',
      displayName: 'Appr Booking Kendaraan',
      meta: {
        icon: 'no_crash',
      },
    },
    {
      name: 'appr_hr_kendaraan',
      displayName: 'Appr Booking Kendaraan',
      meta: {
        icon: 'no_crash',
      },
    },
    {
      name: 'appr_security_kendaraan',
      displayName: 'Appr Booking Kendaraan',
      meta: {
        icon: 'no_crash',
      },
    },
    {
      name: 'appr_mgr_permit',
      displayName: 'Appr Dinas Keluar',
      meta: {
        icon: 'workspace_premium',
      },
    },
    {
      name: 'appr_hr_permit',
      displayName: 'Appr Dinas Keluar',
      meta: {
        icon: 'workspace_premium',
      },
    },
  ] as INavigationRoute[],
}
