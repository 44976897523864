<template>
  <VaSidebar v-model="writableVisible" :width="sidebarWidth" :color="color" minimized-width="0">
    <VaAccordion v-model="value" multiple>
      <VaCollapse v-for="(routeData, index) in listModul" :key="index">
        <template #header="{ value: isCollapsed }">
          <VaSidebarItem
            :to="routeData.children.length > 0 ? undefined : { name: routeData.name }"
            :active="routeHasActiveChild(routeData)"
            :active-color="activeColor"
            :text-color="textColor(routeData)"
            :aria-label="`${routeData.children ? 'Open category ' : 'Visit'} ${routeData.displayName}`"
            role="button"
            hover-opacity="0.10"
          >
            <VaSidebarItemContent class="py-3 pr-2 pl-4">
              <VaIcon
                v-if="routeData.meta.icon"
                aria-hidden="true"
                :name="routeData.meta.icon"
                size="25px"
                :color="iconColor(routeData)"
              />
              <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
                {{ routeData.displayName }}
                <VaIcon
                  v-if="routeData.children.length > 0"
                  :name="arrowDirection(isCollapsed)"
                  size="20px"
                  color="textPrimary"
                />
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
        </template>
        <template #body>
          <div v-for="(childRoute, index2) in routeData.children" :key="index2">
            <VaSidebarItem
              :to="{ name: childRoute.name }"
              :active="isActiveChildRoute(childRoute)"
              :active-color="activeColor"
              :text-color="textColor(childRoute)"
              :aria-label="`Visit ${routeData.displayName}`"
              hover-opacity="0.10"
            >
              <VaSidebarItemContent class="py-3 pr-2 pl-11">
                <VaSidebarItemTitle class="leading-5 font-semibold">
                  {{ childRoute.displayName }}
                </VaSidebarItemTitle>
              </VaSidebarItemContent>
            </VaSidebarItem>
          </div>
        </template>
      </VaCollapse>
    </VaAccordion>
  </VaSidebar>
</template>
<script lang="ts" setup>
import { watch, ref, computed, defineProps } from 'vue'
import { useRoute } from 'vue-router'
// import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'
// import navigationRoutes, { type INavigationRoute } from './NavigationRoutes'
import axios from 'axios'

const props = defineProps({
  visible: { type: Boolean, default: true },
  mobile: { type: Boolean, default: false },
})
const emit = defineEmits(['update:visible'])

const listModul = ref<any>([])
const { getColor, colorToRgba } = useColors()
const route = useRoute()
const value = ref<boolean[]>([])
const writableVisible = computed({
  get: () => props.visible,
  set: (v: boolean) => emit('update:visible', v),
})

const getModule = async () => {
  const res = await axios.get('getModules', {
    params: {
      iduser: window.localStorage.getItem('iduser'),
    },
  })
  listModul.value = res.data
}

const isActiveChildRoute = (child: any) => route.name === child.name
const routeHasActiveChild = (section: any) => {
  if (!section.children || section.children.length === 0) {
    return route.path.endsWith(`${section.name}`)
  }
  return section.children.some(({ name }: any) => route.path.endsWith(`${name}`))
}

const setActiveExpand = () => (value.value = listModul.value.map((route: any) => routeHasActiveChild(route)))

const sidebarWidth = computed(() => (props.mobile ? '100vw' : '280px'))
const color = computed(() => getColor('background-secondary'))
const activeColor = computed(() => colorToRgba(getColor('focus'), 0.1))

const iconColor = (route: any) => (routeHasActiveChild(route) ? 'primary' : 'secondary')
const textColor = (route: any) => (routeHasActiveChild(route) ? 'primary' : 'textPrimary')
const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down')

watch(() => route.fullPath, setActiveExpand, { immediate: true })
getModule()
</script>
